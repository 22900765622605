.navbar-user-dropdown {
    left: -8.1rem !important;
}

.nav-dropdown-depot .nav-link {
    color       : #1e2125 !important;
    padding-left: 1rem !important;
}

.nav-dropdown-depot .nav-link:focus {
    color: #f00fa6 !important;
}

.nav-dropdown-depot .nav-link:hover {
    background-color: #e9ecef !important;
}

.bottom-right {
    bottom           : -0.2rem !important;
    left             : 4.5rem !important;
    font-size        : 1rem;
    /* color         : #dfea87; */
    color            : #32beff;
}

.text-depot {
    font-size: 0.8rem;
}

.font-orbitron {
    font-family: Orbitron;
}

.avatar-image {
    transition-duration: 0.8s;
    transition-property: filter;
}

.avatar-image:hover {
    filter: invert();
}