.calendar-main {
	/* width   : 100%; */
	/* overflow-x: scroll; */
	/* overflow-y: hidden; */
	/* height: 93vh; */
	padding  : 0 0 10px 0;
	/* margin: 10px 10px; */
}

.calendar-table {
	/* overflow-x: hidden; */
	width                : 100%;
	border-collapse      : collapse;
	/* font-size         : 0.9em !important; */
	min-width            : 1500px;
	font-size            : 12px !important;
	/* table-layout      : fixed; */
}

.calendar-table thead {
	/* background-color: var(--color-blue-light); */
	background-color: var(--color-gray-darker);
}

.react-datepicker-popper {
	z-index: 10005 !important;
}

.date-range {
	color: white;
}

.nav {
	border-bottom: 0.2px groove rgb(139, 139, 139) !important;
}

.thead-nav th {
	padding: 0 !important;
}

.table_navbar {
	max-width    : 100vw !important;
	border-bottom: 0.2px groove rgb(139, 139, 139) !important;
}

th,
td,
tr,
.card-body-row {
	padding-left : 4px !important;
	padding-right: 1px !important;
	margin       : 1px;
}

.card-body-row div {
	padding     : 0 3px;
	/* margin   : 0; */
	/* min-width: 200px; */
}

.text-truncate-3 {
	display           : -webkit-box;
	-webkit-line-clamp: 3 !important;
	-webkit-box-orient: vertical;
	overflow          : hidden;
}

.text-truncate-2 {
	display           : -webkit-box;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical;
	overflow          : hidden;
}

.text-truncate-1 {
	display           : -webkit-box;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical;
	overflow          : hidden;
}

.page-item:hover {
	cursor: pointer;
}

#calendar-pagination .page-item a {
	font-size: 0.8rem !important;
	padding  : 0.15rem 0.8rem;
}

.navbar-fixed-left {
	/* background-color: white; */
	/* height: 40px; */
	background-color: var(--color-gray-darker);
	left            : 0px;
	top             : 0px;
	position        : sticky;
	z-index         : 400;

}

.calendar-table thead tr:nth-child(1) th {
	background-color: var(--color-gray-darker);
	position        : sticky !important;
	top             : 35px !important;
	border-style    : none !important;
	z-index         : 10 !important;

}

.calendar-table thead tr:nth-child(2) th {
	background-color: var(--color-gray-darker);
	color           : var(--color-gray-light);
	position        : sticky;
	top             : 55px;
	z-index         : 10;

}


.calendar-table thead tr:nth-child(5) th {
	background-color: var(--color-gray-darker);
	position        : sticky;
	height          : 25px;
	top             : 75px;
	z-index         : 10;
	padding         : 0.3rem 0.2rem !important;
}


.bg-highlight {
	background-color: var(--color-pink) !important;
}


.table-head-row th,
.calendar-table tbody tr td {
	max-width: 5rem !important;
}

th .day-name {
	font-size: 0.9rem;
}

th .day-date {
	font-size: 0.8rem;
}

.day-info1 {
	color      : var(--color-orange) !important;
	font-weight: normal !important;
	font-size  : 0.8rem;
}

.day-info2 {
	color      : var(--color-pink) !important;
	font-weight: normal !important;
	font-size  : 0.8rem;
}

.day-info3 {
	color    : var(--color-green) !important;
	font-size: 0.8rem;
}

.day-info4 {
	color    : var(--color-green-light) !important;
	font-size: 0.8rem;
}






/* events */
.calendar-table .card {
	background-color: var(--color-gray-dark);
	color           : var(--color-gray-light);
	margin-top      : 3px;
}

.calendar-table .card:hover {
	/* color           : black; */
	background-color: var(--color-gray-darker) !important;
	cursor          : pointer;
	border-color    : var(--color-green) !important;
}

.calendar-table tbody {
	background-color: var(--color-gray-darker);
}

.card-header {
	font-size: 0.8rem;
}



/* date picker */
.react-datepicker-wrapper {
	display: block !important;
}



/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.modal .close {
	color: white !important;
}

/* styling adjustments... */
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: white;
	transition             : background-color 5000s ease-in-out 0s;
}

.react-datepicker__time-container--with-today-button {
	right : -87px;
	border: 0px;
}

.modal {
	z-index: 1040;
}

.modal-2 {
	z-index: 1050;
}