.center {
  display        : flex;
  justify-content: center;
  align-items    : center;
  height         : 70vh;
  min-height     : 300px;
}

.login-form {
  min-width: 22rem;
  max-width: 20rem;
}