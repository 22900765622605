.font-orbitron {
    font-family: Orbitron;
}

.img-home-background {
    position  : fixed;
    display   : block;
    width     : 100vw;
    height    : 100vh;
    object-fit: cover;
    top       : -1.3rem;
    /* opacity   : 0.7 !important; */
    filter: brightness(0.7);
}

.homepage-main {
    position: absolute;
}