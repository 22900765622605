.workshop-col-main {
    min-height: 20rem;
}

.workshop-col-header {
    height: 2rem;
}

.workshop-col-content {
    overflow-y: auto;
    overflow-x: hidden;
    height    : calc(100% - 2rem - 2.5rem);
}

.workshop-col-footer {
    height: 2.5rem;
}