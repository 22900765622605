// Override default variables before the import

// Navbar colors

// .bg-black {
//   background-color: black;
// }

$theme-colors: (
  "primary": #0d6efd,
  "sendary-extra": #141518,
  "darker": #111314,
  "secondary": #2d3238,
  "light": #888888,
  "success": #39a883,
  "danger": #f73c33,
  "orange": #ffa349,
  "info": #ffbb00,
  "yellow": #fffe00,
  "lime": #dfea87,
  "pink": #f00fa6,
  "light-blue": #32beff,
  "dark-green": #137265,
  "background-color": #1b2025,
);

$h1-font-size: 3rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.1rem;
$h4-font-size: 0.9rem;
$h5-font-size: 0.8rem;
$h6-font-size: 0.7rem;

$color-white: #ffffff;
$color-black: #000000;
$color-gray: #2a2d30;
$color-gray-dark: #1b2025;
$color-gray-darker: #111314;
$color-gray-light1: #686565;
$color-gray-light: #a7a9ab;
$color-pink: #f00fa6;
$color-green: #39a883;
$color-success: #39a883;
$color-orange: #ffbb00;
$color-green-light: #dfea87;
// default colors:
:root {
  --color-orange: #{$color-orange};
  --color-pink: #{$color-pink};
  --color-green: #{$color-green};
  --color-green-light: #{$color-green-light};
  
  --color-gray-light: #{$color-gray-light};
  --color-gray-light1: #{$color-gray-light1};
  --color-gray-dark: #{$color-gray};
  --color-gray-darker: #{$color-gray-dark};
}

$btn-close-color: $color-gray-light;

@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"), url(../fonts/Comfortaa/static/Comfortaa-SemiBold.ttf) format("SemiBold");
}

@font-face {
  font-family: "NovaSquare";
  src: local("NovaSquare"), url(../fonts/NovaSquare/NovaSquare-Regular.ttf);
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron"), url(../fonts/Orbitron/static/Orbitron-Medium.ttf);
}

// $font-family-sans-serif: "Comfortaa";

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
}

.div-center-vert {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 87vh;
  // width: 50%;
}

body,
html {
  height: 100%;
  width: 100%;
  color: white !important;
  overflow: hidden;
  // background-color: var(--color-gray-darker) !important;
  // font-family: "Arial" !important;
  // font-weight: black;
}

$body-bg: $color-gray-dark;

// navbar
$navbar-dark-hover-color: $color-white;
$navbar-dark-active-color: $color-pink;
$navbar-dark-color: #c4c4c4;
$navbar-dark-toggler-border-color: transparent;
$navbar-padding-x: 1rem;
$navbar-padding-y: 0rem;
$nav-link-color: $color-gray-light;
$nav-link-hover-color: $color-white;
$nav-tabs-link-active-color: $color-pink;
$nav-tabs-link-active-bg: $color-gray-darker;
.navbar-main {
  z-index: 2000;
}
label {
  color: var(--color-gray-light) !important;
}
#root {
  width: 100%;
  height: 100%;
  // min-width: 20rem;
}

.scroll {
  overflow: auto;
  height: calc(100% - 58px);
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .form-control::placeholder {
//   color: rgb(170, 170, 170) !important;
// }
.form-control:read-only {
  color: $color-gray-light;
  background-color: var(--color-gray-darker) !important;
}

// BUTTON
// .btn {
//   border-radius: 15px !important;
//   font-weight: 100 !important;
// }

// Modal loading
.modal-dialog-loading .modal-content {
  background-color: transparent;
  border: transparent;
}

// // CARD
.card {
  background-color: $color-gray !important;
  // border-radius: 10px !important;
}

// GENERAL
$border-color: $color-gray;
$border-radius: 0.75rem;
$card-border-width: 2px;
$border-radius-sm: 1rem;
$border-radius-lg: 0.75rem;
// $border-width: 0em;
$nav-tabs-link-active-border-color: $color-gray;
$component-active-bg: $color-green;

// $dropdown-link-hover-bg: red;
$dropdown-padding-y: 1rem;

// pagination
$pagination-color: $color-white;
$pagination-bg: $color-gray;
$pagination-border-color: $color-gray-dark;
// $pagination-hover-bg: darken($pagination-bg, 3%);
$pagination-hover-color: $color-pink;
// $pagination-hover-border-color: #20c997;
// $pagination-disabled-color: $color-gray;
$pagination-disabled-border-color: $color-gray-dark;
$pagination-disabled-bg: $color-gray;

//modal
$modal-content-bg: $color-gray-dark;
$modal-content-border-color: $color-gray;
$modal-header-padding-y: 0.6rem;
.modal {
  z-index: 3000 !important;
}

//input
$input-bg: $color-gray;
$input-border-color: $color-green;
$input-border-width: 0.1rem;
$input-color: $color-white;
$input-focus-border-color: $color-pink;
$input-transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
$input-plaintext-color: $color-white;
$input-group-addon-bg: $color-gray;

// $form-check-input-focus-box-shadow: $color-orange;
//form group
// $form-group-margin-bottom: 0.6rem;
$form-label-margin-bottom: 0.3rem;

// button
$input-btn-focus-box-shadow: 0px 1px 1px rgba(163, 2, 2, 0.075) inset, 0px 0px 8px rgba(240, 15, 166, 0);
// $btn-padding-y: 0;
// $input-btn-padding-y: 0;
$input-btn-padding-y: 0.175rem;
$input-btn-padding-y-sm: 0;
$btn-padding-y-sm: 0;
$btn-padding-y: 0.15rem;
// $input-btn-padding-y-lg: 0.3rem;

// $dropdown-item-padding-x: 30px;

// //.dropdown-menu
// .dropdown-menu {
//   border-radius: 10px !important;
// }

// $toast-background-color: rgba($color-gray-light, 1);
// $toast-color: rgba($color-gray, 1);

// close button
$btn-close-opacity: 1;

.Toastify__toast--success {
  background-color: $color-success !important;
}

.modal-backdrop.show {
  opacity: 0.5;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop {
  z-index: 1000 !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

// responsive Table
.tdBefore {
  color: $color-gray-light;
}

.trCustom:hover {
  background-color: #323539;
  // border-bottom: #39a883 !important;
  // color: $color-gray-light;
}

.tr-light {
  background-color: #2c3034;
}

.tr-dark {
  background-color: #212529;
}

.tr-deleted td div {
  color: var(--color-gray-light1) !important;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
// @import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Awesome Fonts
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
